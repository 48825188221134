<template>
  <b-card>
    <b-card-title>Existing accounts</b-card-title>
    <b-card-body>
      <b-table
        v-if="accounts.length !== 0"
        :items="accounts"
        :fields="fields"
        striped
        responsive
        :sort-by="'createdAt'"
        :sort-desc="true"
      >

        <template #table-busy>
          <table-loading />
        </template>

        <template #cell(show_details)="row">
          <show-details
            show-text="Show child accounts"
            :row="row"
          />
        </template>

        <template #row-details="row">
          <children-account-row-detail :row="row" />
        </template>

        <template #cell(account_id)="data">
          <b-link :to="{name: accountDetailLink, params: {id: data.item.account_id}}">
            {{ data.item.account_id }}
          </b-link>
        </template>

        <template #cell(state)="data">
          <b-badge
            :variant="STATE_VARIANTS[data.item.state]"
          >
            {{ data.item.state }}
          </b-badge>
          <span v-if="data.item.under_review">under review</span>
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.createdAt) }}
        </template>
      </b-table>
      <p v-else>
        Account doesnt exist yet!
      </p>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardTitle, BCardBody, BLink, BBadge, BTable,
} from 'bootstrap-vue'
import { STATE_VARIANTS } from '@/enums/accounts'
import { formatDateTime } from '@/plugins/formaters'
import TableLoading from '@/components/ui/tables/TableLoading.vue'
import ShowDetails from '@/components/ui/tables/ShowDetails.vue'
import ChildrenAccountRowDetail from '@/components/entity/account/components/ChildrenAccountRowDetail.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BLink,
    BBadge,
    BTable,
    TableLoading,
    ShowDetails,
    ChildrenAccountRowDetail,
  },
  props: {
    accounts: Array,
  },
  data() {
    return {
      STATE_VARIANTS,
      fields: [
        'show_details',
        {
          key: 'account_id',
          sortable: false,
        },
        {
          key: 'state',
          sortable: false,
        },
        {
          key: 'createdAt',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    accountDetailLink() {
      return this.$parent.useExternalLinks ? 'account-detail-ext' : 'account-detail'
    },
  },
  methods: {
    formatDateTime,
  },
}
</script>
