<template>
  <order-detail
    :show-tool-bar="false"
    :use-external-links="true"
  />
</template>
<script>
import OrderDetail from '@/views/order/detail/OrderDetail.vue'

export default {
  components: {
    OrderDetail,
  },
}
</script>
