<template>
  <div v-if="loaded">
    <!--ToolBar-->
    <div class="d-flex align-items-center flex-wrap">
      <b-button-toolbar
        v-if="showToolBar"
        aria-label="Available actions"
        class="my-2"
      >
        <b-button-group>
          <b-button
            v-if="order.state === 'unpaid' || order.state === 'pending'"
            variant="primary"
            @click="onCreateAccount"
          >
            Set order as paid & create account
          </b-button>
          <b-button
            variant="info"
            @click="onEdit"
          >
            <feather-icon
              icon="EditIcon"
              size="18"
            />
            Edit
          </b-button>
          <b-dropdown
            class="mx-1"
            right
            text="Change state"
            variant="secondary"
          >
            <b-dropdown-item
              v-for="(state, key) in ORDER_STATES"
              :key="key"
              @click="onChangeOrderState(state.name)"
            >
              <feather-icon
                v-if="state.name === order.state"
                icon="CheckIcon"
                size="21"
              />
              {{ state.title }}
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>

      </b-button-toolbar>
      <!--/ToolBar-->

      <h2 class="m-1">
        <b-link :to="{name: userDetailLink, params: {id: order.user.id}}">
          {{ order.user.email }}
        </b-link>
      </h2>
      <span
        v-if="order.discount_code"
        class="m-lg-2 m-sm-1"
      >
        Discount: {{ order.discount_code.code }} (
        <b-link
          v-if="!useExternalLinks"
          :to="{name: 'discount-detail', params: {id: order.discount_code.discount.id}}"
        >
          {{ order.discount_code.discount.name }} - {{ order.discount_code.discount.absolute_value === null ? `${order.discount_code.discount.relative_value}%` : formatCurrency(order.discount_code.discount.absolute_value, 'USD') }}
        </b-link>
        <span v-else>
          {{ order.discount_code.discount.name }} - {{ order.discount_code.discount.absolute_value === null ? `${order.discount_code.discount.relative_value}%` : formatCurrency(order.discount_code.discount.absolute_value, 'USD') }}
        </span>
        )
      </span>
      <b-badge
        v-if="!order.discount_code && Math.round(order.original_price * 0.9 * 1000)/1000 === Math.round(order.price * 1000)/1000 && order.original_price !== 0"
        class="m-1 m-lg-2 m-sm-1"
        variant="light-danger"
        size="lg"
      >
        Discount reset 10% {{ Math.round(order.original_price * 0.9 * 1000)/1000 === Math.round(order.price * 1000)/1000 }}
      </b-badge>
    </div>

    <div v-if="order.state === 'paid'">
      <b-row>
        <b-col
          cols="12"
          xl="9"
          md="8"
          class="mx-auto"
        >
          <success-card />
        </b-col>
        <b-col
          cols="12"
          xl="9"
          md="8"
          class="mx-auto"
        >
          <invoice :order="order" />
        </b-col>
      </b-row>
    </div>
    <div
      v-else
    >
      <b-row>
        <b-col
          cols="12"
          xl="9"
          md="8"
          class="mx-auto"
        >
          <status-card
            v-if="order.state === 'refund'"
            title="The order has been refunded"
            variant="primary"
            icon="CheckCircleIcon"
            text=""
          />
          <status-card
            v-if="order.state === 'unpaid'"
            title="The order has been received"
            variant="primary"
            icon="CheckCircleIcon"
            text="Order is not paid yet"
          />
          <status-card
            v-if="order.state === 'pending'"
            title="Pending payment"
            text="Payment has been accepted and now is waiting for confirmation from payment processor."
            variant="warning"
            icon="ClockIcon"
          />
          <status-card
            v-if="order.state === 'canceled'"
            icon="XOctagonIcon"
            variant="danger"
            title="The order has been canceled"
            text="Order has been canceled because we haven't received any payment within 3 days."
          />
          <order-summary />
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col
        cols="12"
        xl="9"
        md="8"
        class="mx-auto"
      >
        <b-card>
          <b-card-title>Additional order data</b-card-title>
          <b-card-body>
            <json-recursive-list :item="order.order_data" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!--Accounts-->
    <b-row>
      <b-col
        cols="12"
        xl="9"
        md="8"
        class="mx-auto"
      >
        <accounts-list :accounts="order.accounts" />
      </b-col>
    </b-row>
    <!--/Accounts-->
    <!--Payments-->
    <b-row>
      <b-col
        cols="12"
        xl="9"
        md="8"
        class="mx-auto"
      >
        <payments-list
          :payments="payments"
        />
      </b-col>
    </b-row>
    <!--/Payments-->
    <b-modal
      id="orderModal"
      title="Order Edit"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <order-form
        :order="order"
        @submit="onEditSubmit()"
      />
    </b-modal>
  </div>
</template>
<script>
import { dispatch, get } from 'vuex-pathify'
import {
  BBadge,
  BRow,
  BCol,
  BButton,
  BButtonGroup,
  BButtonToolbar,
  BDropdown,
  BDropdownItem,
  BLink,
  BCard,
  BCardTitle,
  BCardBody,
  BModal,
} from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Invoice from '@/views/order/detail/components/Invoice.vue'
import SuccessCard from '@/views/order/detail/components/SuccessCard.vue'
import StatusCard from '@/views/order/detail/components/StatusCard.vue'
import OrderSummary from '@/views/order/detail/components/OrderSummary.vue'
import AccountsList from '@/components/entity/account/AccountsList.vue'
import PaymentsList from '@/views/order/detail/components/PaymentsList.vue'
import { ORDER_STATES } from '@/enums/orders'
import store from '@/store'
import OrderAPI from '@/api/order'
import JsonRecursiveList from '@/components/page/JsonRecursiveList.vue'
import { formatCurrency } from '@/plugins/formaters'
import OrderForm from '@/components/forms/order/OrderForm.vue'

export default {
  components: {
    BBadge,
    OrderForm,
    BModal,
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BDropdown,
    BDropdownItem,
    BLink,
    BCard,
    BCardBody,
    BCardTitle,
    FeatherIcon,
    Invoice,
    SuccessCard,
    StatusCard,
    OrderSummary,
    AccountsList,
    PaymentsList,
    JsonRecursiveList,
  },
  props: {
    showToolBar: {
      type: Boolean,
      default: true,
    },
    useExternalLinks: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      ORDER_STATES,
    }
  },
  computed: {
    orderNumber() {
      return this.$route.params.id
    },
    ...get('ordersList@row', { order: 'order_data', accounts: 'accounts', payments: 'payments' }),
    userDetailLink() {
      return this.useExternalLinks ? 'user-detail-ext' : 'user-detail'
    },
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    formatCurrency,
    loadData() {
      dispatch('ordersList/get', this.orderNumber).then(() => {
        this.loaded = true
      }).catch(err => {
        if (err.status === 404) {
          this.$router.push({ name: 'error-404' })
        }
      })
    },
    async onChangeOrderState(state) {
      const answer = await this.confirmBox(`Do you want update order state to ${state}`)
      if (answer) this.changeOrderState(state)
    },
    changeOrderState(state) {
      this.order.state = state
      this.loaded = false
      const data = {
        state: this.order.state,
      }
      if (state === 'paid') data.payment_datetime = new Date().toISOString()
      store.dispatch('ordersList/update', { id: this.order.id, data }).then(() => {
        dispatch('ordersList/get', this.order.id)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            text: 'Order edit was successful',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CheckCircleIcon',
            text: 'An error occurred during order edit',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.loaded = true
      })
    },
    async onCreateAccount() {
      const answer = await this.confirmBox('Do you want set this order as paid and create account?')
      if (answer) {
        this.createAccount()
      }
    },
    createAccount() {
      this.loaded = false
      OrderAPI.createAccountWithOrder(this.order.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create account',
              icon: 'SendIcon',
              text: 'Account was create successfully',
              variant: 'success',
            },
          })
          this.loadData()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CheckCircleIcon',
              text: 'Account creating failed',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.loaded = true
        })
    },
    confirmBox(msg) {
      return new Promise(resolve => {
        this.$bvModal
          .msgBoxConfirm(msg, {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            resolve(value)
          })
      })
    },
    onEdit() {
      this.$bvModal.show('orderModal')
    },
    onEditSubmit() {
      this.$bvModal.hide('orderModal')
      dispatch('ordersList/get', this.order.order_number)
    },
  },
}
</script>
