<template>
  <div class="invoice-preview">

    <b-card
      no-body
      class="invoice-preview-card"
    >

      <b-card-body class="invoice-padding pb-0">

        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
          <div>
            <div class="logo-wrapper">
              <e8-logo />
              <h3 class="text-primary invoice-logo">
                Funding
              </h3>
            </div>
            <p class="card-text mb-25">
              E8 FUNDING, LLC
            </p>
            <p class="card-text mb-25">
              100 Crescent Ct, Unit 700
            </p>
            <p class="card-text mb-0">
              Dallas, TX 75201, United States
            </p>
          </div>

          <div class="mt-md-0 mt-2">
            <h4 class="invoice-title">
              Order
              <span class="invoice-number">#{{ order.order_number }}</span>
            </h4>
            <div class="invoice-date-wrapper">
              <p class="invoice-date-title">
                Date Issued:
              </p>
              <p class="invoice-date">
                {{ formatDate(order.createdAt) }}
              </p>
            </div>
            <div class="invoice-date-wrapper">
              <p class="invoice-date-title">
                Due Date:
              </p>
              <p class="invoice-date">
                {{ formatDate(order.due_datetime) }}
              </p>
            </div>
          </div>
        </div>
      </b-card-body>

      <hr class="invoice-spacing">

      <b-card-body
        v-if="order.invoice_address"
        class="invoice-padding pt-0"
      >
        <b-row class="invoice-spacing">

          <b-col
            cols="12"
            xl="6"
            class="p-0"
          >
            <h6 class="mb-2">
              Invoice To:
            </h6>
            <h6 class="mb-25">
              {{ order.invoice_address.firstName }} {{ order.invoice_address.lastName }}
            </h6>
            <p class="card-text mb-25">
              {{ order.invoice_address.address }}
            </p>
            <p class="card-text mb-25">
              {{ order.invoice_address.zipcode }} {{ order.invoice_address.city }} {{ order.invoice_address.state }}
            </p>
            <p class="card-text mb-25">
              {{ order.invoice_address.country.name }}
            </p>
          </b-col>

          <b-col
            xl="6"
            cols="12"
            class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
          >
            <div>
              <h6 class="mb-2">
                Payment Details:
              </h6>
              <table>
                <tbody>
                  <tr>
                    <td class="pr-1">
                      Payment method:
                    </td>
                    <td>{{ order.processor }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      Paid at:
                    </td>
                    <td>{{ formatDateTime(order.payment_datetime) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <b-table-lite
        v-if="order.product"
        responsive
        :items="[order.product]"
        :fields="['name', 'deposit', 'currency', 'price']"
      >
        <template #cell(deposit)="data">
          {{ formatCurrency(data.item.deposit, data.item.currency.label) }}
        </template>

        <template #cell(currency)="data">
          {{ data.item.currency.label }}
        </template>

        <template #cell(price)="data">
          {{ formatCurrency(data.item.price, 'USD') }}
        </template>
      </b-table-lite>
      <b-table-lite
        v-else
        responsive
        :items="[order]"
        :fields="['item_description', 'price']"
      >
        <template #cell(price)="data">
          {{ formatCurrency(data.item.price, 'USD') }}
        </template>
      </b-table-lite>

      <b-card-body class="invoice-padding pb-0">
        <b-row>

          <b-col
            cols="12"
            md="6"
            class="mt-md-6 d-flex justify-content-end"
            order="1"
            order-md="2"
          >
            <div class="invoice-total-wrapper">
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Subtotal:
                </p>
                <p class="invoice-total-amount">
                  {{ formatCurrency(order.original_price, 'USD') }}
                </p>
              </div>
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Discount:
                </p>
                <p class="invoice-total-amount">
                  {{ formatCurrency(order.discount, 'USD') }}
                </p>
              </div>
              <hr class="my-50">
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Total:
                </p>
                <p class="invoice-total-amount">
                  {{ formatCurrency(order.price, 'USD') }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <hr class="invoice-spacing">

      <b-card-body class="invoice-padding pt-0">
        <span class="font-weight-bold">Note: </span>
        <span>if you have any questions do not hesitate to contact our support team at <a href="mailto:support@e8funding.com">support@e8funding.com</a></span>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite,
} from 'bootstrap-vue'
import { formatDateTime, formatCurrency, formatDate } from '@/plugins/formaters'
import E8Logo from '@/components/Logo.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    E8Logo,
  },
  props: {
    order: Object,
  },
  methods: {
    formatDateTime,
    formatCurrency,
    formatDate,
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.invoice-preview {
  .logo-wrapper {
    img, svg{
      height: 50px;
    }
  }
}
</style>
