<template>
  <b-table
    v-if="accounts.length !== 0"
    :items="accounts"
    :fields="fields"
    striped
    responsive
    :sort-by="'createdAt'"
    :sort-desc="true"
  >
    <template #cell(account_id)="data">
      <b-link :to="{name: 'account-detail', params: {id: data.item.account_id}}">
        {{ data.item.account_id }}
      </b-link>
    </template>

    <template #cell(account_type)="data">
      {{ data.item.account_type_name }} {{ ACCOUNT_SUBTYPE[data.item.account_subtype_name].title }}
    </template>

    <template #cell(state)="data">
      <b-badge
        :variant="STATE_VARIANTS[data.item.state]"
      >
        {{ data.item.state }}
      </b-badge>
    </template>

    <template #cell(createdAt)="data">
      {{ formatDateTime(data.item.createdAt) }}
    </template>
  </b-table>
</template>
<script>
import {
  BLink, BBadge, BTable,
} from 'bootstrap-vue'
import { STATE_VARIANTS, ACCOUNT_SUBTYPE } from '@/enums/accounts'
import { formatDateTime } from '@/plugins/formaters'

export default {
  components: {
    BLink,
    BBadge,
    BTable,
  },
  props: {
    accounts: Array,
  },
  data() {
    return {
      ACCOUNT_SUBTYPE,
      STATE_VARIANTS,
      fields: [
        {
          key: 'account_id',
          sortable: false,
        },
        {
          key: 'account_type',
          sortable: false,
        },
        {
          key: 'state',
          sortable: false,
        },
        {
          key: 'createdAt',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    formatDateTime,
  },
}
</script>
