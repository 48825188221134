<template>
  <div :class="`vh-center flex-column text-center mb-5 order-card ${skin}-theme`">
    <feather-icon
      :icon="icon"
      size="50"
      class="mb-2"
      :class="`text-${variant}`"
    />
    <p
      :class="`text-${variant}`"
      class="font-weight-bold"
    >
      {{ title }}
    </p>
    <p class="mb-0">
      {{ text }}
    </p>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  props: {
    icon: String,
    title: String,
    text: String,
    variant: String,
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
  },
}
</script>
