<template>
  <b-card>
    <b-card-title>Existing payments</b-card-title>
    <b-card-body>
      <b-table
        v-if="payments.length !== 0"
        :items="payments"
        :fields="fields"
        striped
        responsive
        :sort-by="'createdAt'"
        :sort-desc="true"
      >
        <template #cell(show_details)="row">
          <b-button
            size="sm"
            class="mr-2"
            variant="outline-secondary"
            @click="showDetail(row.item)"
          >
            Show Details
          </b-button>
        </template>

        <template #cell(payment_id)="data">
          {{ data.item.payment_id }}
        </template>

        <template #cell(success)="data">
          <b-badge
            v-if="data.item.success"
            variant="success"
          >
            {{ data.item.success }}
          </b-badge>
          <b-badge
            v-else
            variant="danger"
          >
            {{ data.item.success }}
          </b-badge>
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.createdAt) }}
        </template>
      </b-table>
      <p v-else>
        Payment doesnt exist yet!
      </p>
    </b-card-body>
    <b-modal
      id="paymentModal"
      title="Payment detail"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <row-detail
        :item="row"
      />
    </b-modal>
  </b-card>
</template>
<script>
import {
  BCard, BCardTitle, BCardBody, BBadge, BTable, BModal, BButton,
} from 'bootstrap-vue'
import RowDetail from '@/components/page/JsonRecursiveList.vue'
import { STATE_VARIANTS } from '@/enums/accounts'
import { formatDateTime } from '@/plugins/formaters'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BBadge,
    BTable,
    BModal,
    BButton,
    RowDetail,
  },
  props: {
    payments: Array,
  },
  data() {
    return {
      STATE_VARIANTS,
      fields: [
        'show_details',
        {
          key: 'payment_id',
          sortable: false,
        },
        {
          key: 'payment_type',
          sortable: false,
        },
        {
          key: 'success',
          sortable: false,
        },
        {
          key: 'createdAt',
          sortable: false,
        },
      ],
      row: null,
    }
  },
  methods: {
    formatDateTime,
    showDetail(item) {
      this.row = item.payment_data
      this.$bvModal.show('paymentModal')
    },
  },
}
</script>
