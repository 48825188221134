<template>
  <div>
    <b-overlay :show="loading">
      <basic-account-table
        v-if="accounts.length > 0"
        :accounts="accounts"
      />
      <span v-else-if="!loading">No child accounts</span>
    </b-overlay>
    <b-button
      size="sm"
      variant="outline-secondary"
      @click="row.toggleDetails"
    >
      Hide details
    </b-button>
  </div>
</template>

<script>
import { BButton, BOverlay } from 'bootstrap-vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import BasicAccountTable from '@/components/entity/account/components/BasicAccountTable.vue'
import AccountAPI from '@/api/account'

export default {
  components: {
    BasicAccountTable,
    BButton,
    BOverlay,
  },
  props: {
    row: Object,
  },
  data() {
    return {
      accounts: [],
      loading: false,
    }
  },
  mounted() {
    this.getAccountChildren()
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getAccountChildren() {
      if (this.accounts.length === 0) {
        this.loading = true
        AccountAPI.getAccountChildren(this.row.item.id).then(response => {
          this.accounts = response
        }).finally(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>
