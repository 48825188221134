<template>
  <b-card>
    <div class="vh-center flex-column text-center">
      <div class="">
        <feather-icon
          icon="CheckCircleIcon"
          size="50"
          class="text-primary mb-2"
        />
      </div>

      <h4>Order {{ this.$parent.order.order_number }} was successfully completed!</h4>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
  },
}
</script>

<style scoped>

</style>
