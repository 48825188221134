<template>
  <b-table
    :items="orderItem"
    class="table-summary"
    :class="`mb-5 ${skin}-theme`"
  />
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { formatCurrency, formatDate } from '@/plugins/formaters'
import { ACCOUNTS } from '@/enums/accounts'

export default {
  components: {
    BTable,
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    orderItem() {
      const tableData = [
        {
          name: 'Order number:',
          value: this.$parent.order.order_number,
        },
      ]
      if (this.$parent.order.product) {
        tableData.push(...[
          {
            name: 'Account type:',
            value: ACCOUNTS[this.$parent.order.product.account_type],
          },
          {
            name: 'Deposit:',
            value: formatCurrency(this.$parent.order.product.deposit, this.$parent.order.product.currency.label),
          },
          {
            name: 'Provider:',
            value: 'E8 Funding',
          },
          {
            name: 'Platform:',
            value: this.$parent.order.product.platform,
          },
          {
            name: 'Trading currency:',
            value: this.$parent.order.product.currency.label,
          },
          {
            name: 'Price:',
            value: formatCurrency(this.$parent.order.price, 'USD'),
          },
        ])
      } else {
        tableData.push(...[
          {
            name: 'Description:',
            value: this.$parent.order.item_description,
          },
        ])
      }
      tableData.push(...[{
        name: 'Pay by:',
        value: formatDate(this.$parent.order.due_datetime),
      }])

      return tableData
    },
  },
  methods: {
    formatDate,
  },
}
</script>

<style lang="scss" scoped>
.table-summary::v-deep {
  margin-bottom: 0;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  tbody {
    tr:last-child {
      background-color: $primary !important;
      td {
        justify-content: space-between;
        color: white;
        font-size: 18px;
        font-weight: bold;
        background-color: $primary;
      }
    }
  }
  td:last-child, th:last-child {
    text-align: end;
  }
}

</style>
